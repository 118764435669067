<template>
  <div>
    <list-template
        :current-page="page"
        :is-check-input-box="false"
        :loading="loading"
        :table-config="tableConfig"
        :table-data="tableData"
        :total="total"
        @onAdd="$router.push('./add')"
        @onChangePage="handleCurrentChange"
        @onHandle="handleEdit"
        @onSearch="onSearch"
        @onBatchImport="()=>{dialogVisible=true;errorMessage=''}"
        @onExportData="exportData"
    >
      <div slot='title'>
        <!--        <div style="min-width: 150rem !important;margin-bottom: 10rem;">-->
        <!--          <el-button size='mini' style='height:32rem' type='primary' @click="()=>{dialogVisible=true;errorMessage=''}">批量导入</el-button>-->
        <!--          <el-button size='mini' style='height:32rem' type='primary' @click="$router.push('./add')">新增</el-button>-->
        <!--        </div>-->
        <searchBlock :search-config='searchConfig' @onSearch='onSearch'></searchBlock>
      </div>
    </list-template>
    <el-dialog :visible.sync="dialogVisible" title="批量导入">
      <div>
        <span style="display: inline-block;width: 180rem">请下载导入数据模板</span>
        <el-button size="small" @click="onDownload">点击下载模板</el-button>
      </div>
      <div style="margin: 24rem 0;display: flex;align-items: center">
        <span style="display: inline-block;width: 180rem">请上传导入文件</span>
        <el-upload :action="$_register.defaults.baseURL + '/api/recruit-v2/offline/template'" :before-upload="beforeUpload" :headers="{ authorization: 'Bearer ' + $store.getters.token }"
                   :on-success="onSuccess" :show-file-list="false" accept=".xlsx,.xls" class="upload-demo" name="file" style="width: 100rem">
          <el-button size="small" type="primary">点击上传</el-button>
        </el-upload>
      </div>
      <span style="color: red">{{ errorMessage }}</span>
    </el-dialog>
    <el-dialog :visible.sync="qrCodePopUp" title="学生信息填报入口" width="30%">
      <div style="display: flex;justify-content: center">
        <vue-qr :logoScale="0.2" :size="200" :text='url.qr_code' colorDark="#333" colorLight="#fff"></vue-qr>
      </div>
      <div style="text-align: center">
        请截图发送给家长，家长扫码后即可填写完整的学生信息
      </div>
    </el-dialog>
  </div>
</template>
<script>
import VueQr from 'vue-qr'
import {mapState} from 'vuex'
import ListTemplate from "@/components/pages/List.vue"
import searchBlock from "@/components/SearchBlock.vue";
import {getStudentStatus} from "@/api/common"

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  components: {searchBlock, ListTemplate, VueQr},
  data() {
    return {
      loading: true,
      search: {},
      searchConfig: [{
        prop: 'student_name',
        placeholder: '请搜索学生姓名'
      }, {
        prop: 'consignee',
        placeholder: '请搜索家长姓名'
      }, {
        prop: 'school_id',
        placeholder: '请筛选校区',
        options: [],
        tag: 'select',
        label: 'school_name',
        value: 'school_id',
      }, {
        prop: 'parent_association',
        placeholder: '筛选家长填写状态',
        options: [],
        tag: 'select',
        label: 'parent_association_name',
        value: 'parent_association',
      }, {
        prop: 'student_type',
        placeholder: '筛选学生类型',
        options: [],
        tag: 'select',
        label: 'student_type_name',
        value: 'student_type',
      }, {
        prop: 'student_status',
        placeholder: '筛选学生状态',
        options: [],
        tag: 'select',
      }, {
        prop: 'is_training',
        placeholder: '筛选是否为培优生',
        options: [
          {
            label:"否",
            value:0
          },{
            label:"是",
            value:1
          }
        ],
        tag: 'select',
      }],
      tableData: [],
      //总条数
      total: 0,
      pageCount: 0,
      tableConfig: [
        {prop: 'student_name', label: '学生姓名'},
        {prop: 'consignee', label: '家长姓名'},
        {prop: 'student_type', label: '学生类型'},
        {prop: 'school', label: '报名学校'},
        {prop: 'grade_data', label: '入学年级'},
        {prop: 'parent_association', label: '是否已填写'},
        {prop: 'creator', label: '录入人'},
        {prop: 'student_status_cn', label: '学生状态'},
        {prop: 'is_training', label: '是否为培优生',render(row){
          if(row.is_training == null)return "-"
          return row.is_training === 1?"是":"否"
          }},
        {
          prop: 'handle',
          label: '操作',
          width: '120rem',
          handle: true,
          render() {
            return ['编辑', '二维码']
          }
        }
      ],
      dialogVisible: false,
      errorMessage: '',
      qrCodePopUp: false,
      url: {
        qr_code: ''
      }
    }
  },
  created() {
    this.$store.commit('setPage', 1)
    this.getData()
    getStudentStatus().then(res=>{
      this.searchConfig[5].options = res.data.data;
    })
    this.$_register.get('api/recruit-v2/offline/config').then(res => {
      const config = res.data.data
      this.searchConfig[2].options = config.school_data
      this.searchConfig[3].options = config.parent_association_data
      this.searchConfig[4].options = config.student_type_add
      this.searchConfig[2].options.unshift({school_id: 0, school_name: '全部'})
      this.searchConfig[3].options.unshift({parent_association: 0, parent_association_name: '全部'})
      this.searchConfig[4].options.unshift({student_type: 0, student_type_name: '全部'})
    })
  },
  activated() {
    this.getData()
  },
  computed: {
    ...mapState(['page'])
  },
  methods: {
    getData() {
      this.loading = true
      let params = {...this.search, page: this.page}

      this.tableData = []
      this.$_register('api/recruit-v2/offline/list', {params}).then(res => {
        let data = res.data.data
        this.tableData = data.list
        this.total = data.page.total
      }).finally(() => {
        this.loading = false
      })
    },

    onSearch(val) {
      this.$store.commit('setPage', 1)
      this.search = val
      this.getData(val)
    },

    handleEdit(row, text) {
      switch (text.type) {
        case 'edit':
          this.$router.push('./edit?id=' + row.id)
          break
        case 'generateQRCode':
          this.qrCodePopUp = true
          this.url = row
          break
      }
    },

    // 当前页改变触发
    handleCurrentChange(val) {
      //当前页赋值给currentPage
      this.$store.commit('setPage', val)
      //拉取数据
      this.getData()
    },

    // 导出数据
    exportData() {
      let params = {...this.search, page: this.page}
      this.$_register('api/recruit-v2/offline/export', {params}).then(res => {
        this.$tools.download('线下补录名单', res.data)
      })
    },

    onDownload() {
      location.href = process.env.VUE_APP_URL_PROD_BASE + '/template/offline_template.xlsx';
    },
    onSuccess(res) {
      if (res.status === 0) {
        this.$message.success('上传成功')
        this.errorMessage = ''
      } else this.errorMessage = res.msg
    },
    beforeUpload(file) {
      const testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'xls'
      const extension2 = testmsg === 'xlsx'
      if (!extension && !extension2) {
        this.$message({
          message: '上传文件只能是xls、xlsx格式!',
          type: 'warning'
        })
      }
      return extension || extension2
    }
  },
}
</script>
<style lang="scss" scoped>
.role-list {
  padding-top: 24rem;
}

.button {
  border-radius: 4rem;
  box-sizing: border-box;
  height: 34rem;
  margin-bottom: 30rem;
  margin-left: 34rem;
  opacity: 1;
  width: 90rem;
}

.table {
  margin-left: 32rem;
}



::v-deep .el-table--enable-row-transition .el-table__body td.el-table__cell {
  box-sizing: border-box;
  color: #333333;
  font-size: 14rem;
  font-weight: normal;
  height: 48rem;
  text-align: left;
}

.header {
  color: #333333;
  font-size: 16rem;
  font-weight: bold;
  left: 300rem;
  letter-spacing: 3.5rem;
  line-height: 24rem;
  position: fixed;
  top: 120rem;
}

.el-button--text {
  color: #3491fa;
  font-size: 14rem;
  font-weight: normal;
  margin-right: 40rem;
}
</style>
